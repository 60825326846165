import { withAuth } from '@okta/okta-react';
import * as React from 'react';
import {
  Button,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from 'reactstrap';

import useAuth from './auth';

interface UserPropTypes {
  given_name: string,
  family_name: string,
}

const renderUser = (params: UserPropTypes | null) => {
  if (params) {
    return (<NavLink>{params.given_name} {params.family_name}</NavLink>)
  };
  return null;
}

interface NavBarPropTypes {
  auth: any
}

const NavBar = withAuth(({ auth }: NavBarPropTypes) => {
  const [authenticated, user] = useAuth(auth);

  const authInProgress = authenticated === null;
  const title = authInProgress ? 'Logging in...' : 'Log in';

  return (
    <div className="App">
      <Navbar color="dark" dark={true} expand="md">
        <NavbarBrand href="/">Yopass</NavbarBrand>
        <Nav navbar={true} className="ml-auto">
          {(authenticated === true) ? (
            <React.Fragment>
              <NavItem>
                {renderUser(user)}
              </NavItem>
              <NavItem>
                <Button
                  disabled={authInProgress}
                  color="primary"
                  size="md"
                  onClick={() => auth.logout()}
                >
                  <span>Log out</span>
                </Button>
              </NavItem>
            </React.Fragment>
          ) : (
              <NavItem>
                <Button
                  disabled={authInProgress}
                  color="primary"
                  size="md"
                  onClick={() => auth.login()}
                >
                  <span>{title}</span>
                </Button>
              </NavItem>
            )}
        </Nav>
      </Navbar>
    </div >
  );
});

export default NavBar;
