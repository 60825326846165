import { ImplicitCallback, Security } from '@okta/okta-react';
import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import NavBar from './NavBar';

import './App.css';
import Create from './Create';
import DisplaySecret from './DisplaySecret';

const config = {
  client_id: '0oa315g26aTT3KEYx0i7',
  issuer: 'https://sso.klarna.net',
  redirect_uri: window.location.origin + '/implicit/callback',
};

class App extends React.Component {
  public render() {
    return (
      <Router>
        <Security
          client_id={config.client_id}
          issuer={config.issuer}
          redirect_uri={config.redirect_uri}
        >
          <div>
            <NavBar />
            <Container className="margin">
              <Row>
                <Col ml="auto">
                  <Route path="/" exact={true} component={Create} />
                  <Route
                    exact={true}
                    path="/s/:key/:password"
                    component={DisplaySecret}
                  />
                  <Route
                    exact={true}
                    path="/s/:key"
                    component={DisplaySecret}
                  />
                  <Route
                    path="/implicit/callback"
                    component={ImplicitCallback}
                  />
                </Col>
              </Row>
            </Container>
            <Features />
            <Attribution />
          </div>
        </Security>
      </Router>
    );
  }
}

const Features = () => {
  return (
    <Container className="features">
      <hr />
      <p className="lead">
        {' '}
        Yopass is created to reduce the amount of clear text passwords stored in
        email and chat conversations by encrypting and generating a short lived
        link which can only be viewed once.
      </p>
      <p />
      <h6>End-to-End encryption</h6>
      <p>
        Both encryption and decryption are being made locally in the browser,
        the decryption key is not stored with yopass.
      </p>
      <h6>Self destruction</h6>
      <p>
        All messages have a fixed time to live and will be deleted automatically
        after expiration.
      </p>
      <h6>Open source software</h6>
      <p>
        Yopass fully open source meaning full transparency and the possibility
        to submit features, fix bugs or run the software yourself.
      </p>
    </Container>
  );
};

const Attribution = () => {
  return (
    <Container className="text-center">
      <div className="text-muted small">
        Created by <a href="https://github.com/jhaals/yopass">Johan Haals</a>
      </div>
    </Container>
  );
};
export default App;
